"use client";

import { assertNotNullish } from "./assertNotNullish";
import { PageConfig } from "./PageConfig";
import { createContext, useContext } from "react";

const PageConfigContext = createContext<PageConfig | undefined>(undefined);

export const PageConfigContextProvider = (
  args: React.ComponentProps<typeof PageConfigContext.Provider>,
) => <PageConfigContext.Provider {...args} />;

export const usePageConfigContext = (): PageConfig => {
  const context = useContext(PageConfigContext);

  assertNotNullish(
    context,
    "usePageConfigContext was used outside of Provider",
  );

  return context;
};
