import { TimeOfDay } from "./cartoons-occasion-stack.types";

/**
 * When we moved from Cartoons API to Crepo API, the "chapters" were removed and RB added times of day.
 * Previously the "chapters" (activities like "fitness") would be assigned a time of day via our code.
 * RB added twice as many times of day (before we had `morning`, now `morning1`, `morning2`),
 * It was a business decision to have Multiple states for the morning (Sun rising, Sun rising a bit more),
 * in each state there's an order as well.
 * At the moment we don't make use of these and remap them to the existing time of day system.
 *
 * ---
 *
 * These "chapters" are mostly old and can problably be removed...
 * We currently don't have an exact list of the "chapters" that can be entered into Red Bull's system, so this might need to be updated as needed.
 * Original List: Fitness, Drive, Work Blue Collar, Work White Collar, Sport, Study, Free Time On My Own, Gaming, Party & Socialising.
 * We added some popular Big Moments to this list, but there was some ambiguity to those instructions,
 * so as a fallback "Summer/Winter Solstice" and "Christmas & New Year" (which was the list provided), also has "Summer Solstice", "Winter Solstice", "Christmas", "New Years" as we know there are some "Big Moments" named like that.
 */
export const getTimeOfDayForChapter = (chapter: string): TimeOfDay => {
  switch (chapter) {
    case "morning1":
    case "morning2":
    case "Fitness":
    case "Drive":
    case "Work Blue Collar":
    case "Easter":
    case "International Dance Day":
    case "International Friendship Day": {
      return "morning";
    }
    case "noon1":
    case "noon2":
    case "Work White Collar":
    case "Sport":
    case "Study":
    case "Global Recycling Day":
    case "Global Run":
    case "Summer/Winter Solstice":
    case "Summer Solstice":
    case "Winter Solstice": {
      return "noon";
    }
    case "evening1":
    case "evening2":
    case "Free Time On My Own":
    case "Gaming":
    case "Formula 1":
    case "Global Football Events":
    case "Winter Olympics":
    case "Summer Olympics": {
      return "evening";
    }
    case "night1":
    case "night2":
    case "Party & Socialising":
    case "Ramadan":
    case "Halloween":
    case "Lunar New Year":
    case "Diwali":
    case "Christmas & New Year":
    case "Christmas":
    case "New Years": {
      return "night";
    }
    default: {
      /* We might want to report an error to Sentry or something similar in the future */
      // throw new Error(`Could not derive a time-of-day for chapter: ${chapter}`);
      return "morning";
    }
  }
};
