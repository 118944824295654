"use client";

import { getContentfulImageUrl } from "../../helpers/images/getContentfulImageUrl";
import classnames from "classnames/bind";
import styles from "./legal-disclaimer-product.module.css";
import { BlocksProductHeroCan } from "../../__generated__/contentful-types";

const cx = classnames.bind(styles);

export const LegalDisclaimerProduct = ({
  onClickDismiss,
  productHeroCanBlock,
}: {
  onClickDismiss: () => void;
  productHeroCanBlock: BlocksProductHeroCan | undefined;
}) => {
  return (
    <div className={cx("container")} id="container">
      <div className={cx("inner")}>
        <div className={cx("content")}>
          {productHeroCanBlock && productHeroCanBlock.heroArtwork?.url && (
            <div className={cx("image-column")}>
              <img
                className={cx("image")}
                src={
                  productHeroCanBlock.heroArtwork.url &&
                  getContentfulImageUrl(productHeroCanBlock.heroArtwork.url, {})
                }
                alt={productHeroCanBlock.heroArtwork.description ?? undefined}
              />
            </div>
          )}
          <div className={cx("text-column")}>
            <div className={cx("title")}>
              {"CONTAINS ARTIFICIAL SWEETENER AND FOR CALORIE CONSCIOUS"}
            </div>
            <div className={cx("text")}>
              {
                "Red Bull Sugarfree contains the artificial sweeteners Sucralose and Acesulfame Potassium."
              }
              <br />
              {"No sugar added in the product."}
              <br />
              {"Not recommended for children."}
            </div>
          </div>
          <button
            className={cx("button")}
            onClick={() => {
              onClickDismiss();
            }}
          />
        </div>
      </div>
    </div>
  );
};
