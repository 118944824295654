import classnames from "classnames/bind";
import styles from "./legal-disclaimer-chile.module.css";

const cx = classnames.bind(styles);

export const LegalDisclaimerChile = () => (
  <div className={cx("container")}>
    <div className={cx("inner")}>
      <div className={cx("content")}>
        <img
          className={cx("logo")}
          src="https://images.ctfassets.net/lcr8qbvxj7mh/5PUU3uI79EOO2t1Gv03v6x/c6b6f5120aba25e1cf5d66571b0c7f78/Color_Minsal.jpg?h=250"
          alt="Ministerio de Salud"
        />
        <div className={cx("text")}>
          {"PREFIERA ALIMENTOS CON MENOS SELLOS DE ADVERTENCIA"}
        </div>
      </div>
    </div>
  </div>
);
