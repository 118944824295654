"use client";

import { useEffect, useRef } from "react";
import { env } from "../../helpers/env";
import { startAnalytics } from "../../helpers/analytics";
import Script from "next/script";
import { SubAssetType } from "../../helpers/getSubAssetType";
import { usePathname } from "next/navigation";

export const Analytics = ({
  gtmSource,
  title,
  localeString,
  subAssetType,
}: {
  gtmSource: string | null;
  title: string | null;
  localeString: string;
  subAssetType: SubAssetType | null;
}) => {
  const route = usePathname();
  const hasGtmContainer =
    env.NEXT_PUBLIC_HAS_GTM_CONTAINER && localeString !== "ne-xt";

  const analyticsArgsRef = useRef({
    gtmSource,
    title,
    route: route ?? "",
    localeString,
    subAssetType,
  });

  useEffect(() => {
    startAnalytics(analyticsArgsRef.current);
  }, []);

  return hasGtmContainer ? (
    <>
      <Script async src="//qm.redbull.com/gtm.js?id=GTM-TCZQX4" />
      <Script async src="//qm.redbull.com/gtm.js?id=GTM-5XHDS9" />
    </>
  ) : null;
};
