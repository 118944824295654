"use client";

import { Fragment, useEffect, useState } from "react";

/**
 * Copied from Material UI:
 * https://github.com/mui/material-ui/blob/224e38cca0783a5e7e4c6b40807d9af666dfc079/packages/mui-base/src/NoSsr/NoSsr.tsx
 */
export const NoServerSideRendering = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [shouldRender, setShouldRender] = useState<boolean>(false);

  useEffect(() => {
    setShouldRender(true);
  }, []);

  return shouldRender ? <Fragment>{children}</Fragment> : null;
};
