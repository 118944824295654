import { useEffect, useState } from "react";

export const useBrowserLanguage = () => {
  const [browserLanguage, setBrowserLanguage] = useState<string | undefined>(
    undefined,
  );

  useEffect(() => {
    const onChange = () => {
      const navigatorLanguage = navigator.languages?.[0] ?? navigator.language;

      setBrowserLanguage(navigatorLanguage.split("-")[0]);
    };

    onChange();

    window.addEventListener("languagechange", onChange);

    return () => window.removeEventListener("languagechange", onChange);
  }, []);

  return browserLanguage;
};
