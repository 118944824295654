"use client";

import { useState } from "react";
import Cookies from "js-cookie";
import { useBrowserLanguage } from "../../helpers/useBrowserLanguage";
import classnames from "classnames/bind";
import styles from "./locale-switch.module.css";
import { useLocaleStringContext } from "../../helpers/LocaleStringContext";

const cx = classnames.bind(styles);

export const localeSwitchConfigs = [
  {
    localeString: "se-sv",
    language: "en",
    text: "Would you like to switch to the english language site?",
    buttonSwitchText: "Switch",
    buttonStayText: "Stay",
    path: "/se-en/energydrink",
  },
  {
    localeString: "se-en",
    language: "sv",
    text: "Vill du byta till den svenska sidan?",
    buttonSwitchText: "Ändra",
    buttonStayText: "Stanna kvar",
    path: "/se-sv/energydrink",
  },
];

const COOKIE_LOCALE_SWITCH_TYPE = "cookieLocaleSwitchDismissed";

export const LocaleSwitchInner = ({
  localeString,
  language,
  onClickDismissed,
}: {
  localeString: string;
  language: string;
  onClickDismissed: () => void;
}) => {
  const config = localeSwitchConfigs.find(
    (item) => item.localeString === localeString && item.language === language,
  );

  if (!config) {
    return null;
  }

  return (
    <div className={cx("container")} id="container">
      <div className={cx("banner-wrapper")}>
        <div className={cx("wrapper", "wrapper--no-country-selection")}>
          <div className={cx("meta")}>
            <div className={cx("caption")}>{"Switch Location"}</div>
            <div
              className={cx("description")}
              data-locale-switch="description" // <- Used as a selector in other components.
            >
              {config.text}
            </div>
          </div>
          <div className={cx("country-selection")}>
            <div className={cx("buttons")}>
              <a className={cx("button", "button--cta")} href={config.path}>
                {config.buttonSwitchText}
              </a>
              <button className={cx("button")} onClick={onClickDismissed}>
                {config.buttonStayText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const LocaleSwitch = () => {
  const localeString = useLocaleStringContext();

  const browserLanguage = useBrowserLanguage();

  /**
   * Using cookies here implies that this component is not suitable for
   * server-side rendering (SSR) because it will result in client-side hydration
   * mismatches. As such, this component should only ever be rendered in a
   * browser environment.
   */
  const [isLocaleSwitchDismissed, setIsLocaleSwitchDismissed] =
    useState<boolean>(() => Cookies.get(COOKIE_LOCALE_SWITCH_TYPE) === "true");

  return (
    <div>
      {!isLocaleSwitchDismissed && browserLanguage && (
        <LocaleSwitchInner
          localeString={localeString}
          language={browserLanguage}
          onClickDismissed={() => {
            Cookies.set(COOKIE_LOCALE_SWITCH_TYPE, "true");
            setIsLocaleSwitchDismissed(true);
          }}
        />
      )}
    </div>
  );
};
