import classNames from "classnames/bind";
import styles from "./legal-disclaimer.module.css";

const cx = classNames.bind(styles);

export const LegalDisclaimer = ({
  position,
  onClickDismissed,
  message,
  link,
}: {
  position: "top" | "bottom";
  onClickDismissed: () => void;
  message: string;
  link?: {
    text: string;
    url: string;
  };
}) => {
  return (
    <div
      id="container-banner"
      className={cx("container", {
        [`container--${position}`]: true,
      })}
    >
      <div className={cx("inner")}>
        <p
          className={cx("text")}
          dangerouslySetInnerHTML={{ __html: message ?? undefined }}
        />
        {link && (
          <a className={cx("text", "text--link")} href={link.url}>
            {link.text}
          </a>
        )}
        <a
          className={cx("button")}
          onClick={(event) => {
            event.preventDefault();
            onClickDismissed();
          }}
        />
      </div>
    </div>
  );
};
